import React from 'react'
import styled from 'styled-components'

import { ReactComponent as InfoCirle } from '../../images/faInfoCircle.svg'

const query = {
  owner: 'texaspacm',
  id: 'cjjgogt6h2dhu2rpsz0gyhxh0',
  access_token: process.env.GATSBY_MAPBOX_API_TOKEN,
}

const queryString = Object.keys(query).map(key =>
  key + '=' + query[key]
).join('&')

const props = {
  target: '_blank',
  rel: 'noopener',
}

const Container = styled.div`
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 0;
    right: 0;

    .mapboxgl-ctrl-attrib.mapboxgl-compact {
        margin: 10px;
        position: relative;
        background-color: #ffffff;
        border-radius: 3px 12px 12px 3px;
    }

    .mapboxgl-ctrl:hover {
        padding: 2px 24px 2px 4px;
        visibility: visible;
    }

    .mapboxgl-ctrl:hover a + a {
      padding-left: 5px;
    }

    .mapboxgl-ctrl-attrib.mapboxgl-compact::after {
        display: none;
    }
`

const SvgContainer = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 12px;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  padding: 4px;

  display: flex;
  align-items: center;
`

export default () =>
  <Container className='mapboxgl-ctrl-bottom-right'>
    <div className='mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact'>
      <a href='https://www.mapbox.com/about/maps/' {...props}>
        © Mapbox
      </a>
      <a href='http://www.openstreetmap.org/about/' {...props}>
      © OpenStreetMap
      </a>
      <a className='mapbox-improve-map' {...props}
        href={`https://www.mapbox.com/feedback/?${ queryString }`}
      >
        Improve this map
      </a>
      <SvgContainer>
        <InfoCirle width={16} height={16} />
      </SvgContainer>
    </div>
  </Container>
