import React from 'react'

import Button from './Button'
import { Col } from '../Grid'

export default apartment =>
  <Col flex={1}>
    <Button
      type='primary'
      href={apartment.marketingWebsiteUrl}
      tracking={{
        category: 'Apartment Card Link',
        action: 'Click',
        label: apartment.name,
        ...apartment,
      }}
    >
      Open Website
    </Button>
  </Col>
