import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ZoomIn } from '../../images/faPlus.svg'
import { ReactComponent as ZoomOut } from '../../images/faMinus.svg'

const MapControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
`

const Group = styled.div`
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  clear: both;
  pointer-events: auto;
`

const IconButton = styled.button`
  width: 30px;
  height: 30px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  & + & {
    border-top: 1px solid #ddd;
  }
`

export default ({ onZoomIn, onZoomOut }) =>
  <MapControls>
    <Group>
      <IconButton onClick={onZoomIn} title='Zoom In'>
        <ZoomIn width={15} height={15} />
      </IconButton>
      <IconButton onClick={onZoomOut} title='Zoom Out'>
        <ZoomOut width={15} height={15} />
      </IconButton>
    </Group>
  </MapControls>
