import React, { useState, useEffect } from 'react'
import { connectHits, Stats } from 'react-instantsearch-dom'
import { useMedia } from 'use-media'

import ApartmentCard from '../ApartmentCard'
import MapGeoSearch from './MapGeoSearch'
import LocationGeoSearch from './LocationGeoSearch'

import Filter from './Filter'
import Button from '../Card/Button'
import Footer from './Footer'
import { Title, PricingDisclaimer, SubTitle } from './Text'
import Form from './Form'
import Container from '../Main/Container'
import * as Grid from '../Grid'
import BedroomRefinementList from './BedroomRefinementList'
import MoveInDateRange from './MoveInDateRange'
import PriceRange from './PriceRange'
import Reset from './Reset'
import Pagination from './Pagination'

const Search = ({
  hits,
  markets,
}) => {
  const [flyTo, setFlyTo] = useState()
  const [showFilters, setShowFilters] = useState(false)

  // auto open filter on mobile
  const isMobile = useMedia({ maxWidth: 767.9 })

  useEffect(() => {
    if (isMobile) {
      setShowFilters(true)
    }
  }, [isMobile])

  return <>
    {showFilters || !isMobile
      ? null
      : <Filter onClick={() => setShowFilters(true)} />}
    <MapGeoSearch flyTo={flyTo} setFlyTo={setFlyTo} />
    <Grid.Container>
      <Container.Apartments>
        <Grid.Col>
          <Form open={showFilters}>
            {!isMobile ? <Reset attributes={[
              'floorplan.bedrooms',
              'unit.dateAvailableTimestamp',
              'rent',
            ]} /> : null}
            <LocationGeoSearch markets={markets} />
            <BedroomRefinementList
              attribute='floorplan.bedrooms'
            />
            <MoveInDateRange attribute='unit.dateAvailableTimestamp' />
            <PriceRange attribute='rent' />
            {isMobile ? <Footer>
              <Button as='button' onClick={() => setShowFilters(false)}>
                Show { hits.length } apartments
              </Button>
              <Reset attributes={[
                'floorplan.bedrooms',
                'unit.dateAvailableTimestamp',
                'rent',
              ]} link />
            </Footer> : null}
          </Form>
          {hits.length > 0
            ? <Title>Apartments near {hits[0].market[0].split(' > ')[1]}</Title>
            : null}
          <SubTitle>
            <Stats
              translations={{
                stats: totalHits =>
                  totalHits
                    ? `Viewing ${ hits.length } of ${ totalHits } apartments`
                    : 'No results found'
              }}
            />
          </SubTitle>
          {hits.length > 0
            ? <PricingDisclaimer>Prices subject to change</PricingDisclaimer>
            : null}
          <Grid.Row>
            {hits.map((apartment, i) =>
              <Container.Card key={i} fullWidth={hits.length === 1}>
                <ApartmentCard {...apartment}
                  layout={hits.length === 1 ? 'row' : 'column'}
                  onClick={() => setFlyTo(apartment)}
                />
              </Container.Card>
            )}
          </Grid.Row>
          <Grid.Row>
            <Pagination />
          </Grid.Row>
        </Grid.Col>
      </Container.Apartments>
    </Grid.Container>
  </>
}

export default connectHits(Search)
