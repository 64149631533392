import React, { useState, useEffect } from 'react'
import ReactMapGL, { FlyToInterpolator } from 'react-map-gl'
import WebMercatorViewport from 'viewport-mercator-project'

import ErrorBoundary from '../ErrorBoundary'
import MapControls from './MapControls'
import ApartmentMarker from './ApartmentMarker'
import ApartmentPopup from './ApartmentPopup'
import AttributionControl from './AttributionControl'

import { getBoundingBox } from '../../utils'

const Map = React.forwardRef(({
  center,
  apartments,
  zoom = 10,
  flyTo,
  submarkets,
  boundingBox,
  onLoad,
  onViewportChange,
  onTransitionEnd,
  onOpenPopup,
  onClosePopup,
}, ref) => {
  const [viewport, setViewport] = useState({
    mapboxApiAccessToken: process.env.GATSBY_MAPBOX_API_TOKEN,
  })

  const withTransition = {
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  }

  const handleViewportChange = changed => {
    const newViewport = {
      ...viewport,
      ...changed,
    }

    setViewport(newViewport)

    if (onViewportChange) {
      onViewportChange(newViewport)
    }
  }

  const transitionTo = apartment => {
    const {
      lat: latitude,
      lng: longitude,
    } = apartment.coordinates

    handleViewportChange({
      latitude,
      longitude,
      ...withTransition,
    })
  }

  useEffect(() => {
    // Handle centering map and opening popup
    if (flyTo) {
      transitionTo(flyTo)
    }
  }, [flyTo])

  useEffect(() => {
    const {
      lat: latitude,
      lng: longitude,
    } = apartments.length === 1 ? apartments[0].coordinates : (center || {})
    // Handle centering map
    if (latitude && longitude && !boundingBox) {
      handleViewportChange({
        zoom: zoom || 10,
        latitude,
        longitude,
        ...withTransition,
      })
    // Handle setting map bounds (from props or calculated)
    } else if (viewport.width && viewport.height) {
      const box = boundingBox ||
        getBoundingBox(apartments.map(a => a.coordinates))

      if (box) {
        const bounds = [
          [box.northEast.lng, box.northEast.lat],
          [box.southWest.lng, box.southWest.lat],
        ]
        const opts = boundingBox ? {} : { padding: 50 }
        const calculatedViewport =
          new WebMercatorViewport(viewport).fitBounds(bounds, opts)
        handleViewportChange({
          ...calculatedViewport,
          ...withTransition,
        })
      }
    }
  }, [
    center,
    zoom,
    boundingBox,
    apartments,
    viewport.width,
    viewport.height,
  ])

  return <ReactMapGL
    {...viewport}
    ref={ref}
    width='100%'
    height='100%'
    mapStyle='mapbox://styles/texaspacm/cjjgogt6h2dhu2rpsz0gyhxh0'
    onViewportChange={handleViewportChange}
    attributionControl={false}
    onLoad={onLoad}
    onTransitionEnd={onTransitionEnd}
  >
    {apartments.map((apartment, i) =>
      <ApartmentMarker key={i} {...apartment}
        active={apartment === flyTo}
        onClick={() => onOpenPopup(apartment)}
      />
    )}
    {flyTo ? <ApartmentPopup apartment={flyTo}
      onClose={onClosePopup}
    /> : null}
    <MapControls
      onZoomIn={() => handleViewportChange({ zoom: viewport.zoom + 1 })}
      onZoomOut={() => handleViewportChange({ zoom: viewport.zoom - 1 })}
    />
    <AttributionControl />
  </ReactMapGL>
})

export default React.forwardRef((props, ref) =>
  <ErrorBoundary><Map ref={ref} {...props} /></ErrorBoundary>
)
