import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import Layout from '../components/Layout'
import Search from '../components/Search'
import { searchStateToUrl, urlToSearchState } from '../utils'

const SearchTemplate = ({ data, navigate, location }) => {
  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const [lastLocation, setLastLocation] = useState(location)

  useEffect(() => {
    if (location !== lastLocation) {
      setSearchState(urlToSearchState(location))
      setLastLocation(location)
    }
  }, [location])

  const onSearchStateChange = ss => {
    navigate(searchStateToUrl({ location }, ss), ss)
    setSearchState(ss)
  }

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  const {
    account: { menu, markets, ...account },
  } = data.root

  const page = {
    title: 'Search',
    description: '',
    noindex: true,
    termGroup: '',
    utm: {
      'utm_medium': 'advanced search',
    },
    breadcrumb: {
      market: {
        title: 'Market',
        state: { name: 'State' },
        marketPage: { slug: '#' }
      },
      submarket: {
        title: 'Sub-Market',
        state: { name: 'State' },
        marketPage: { slug: '#' }
      },
      submarkets: [],
    },
    apartments: {
      count: 0,
      items: [],
    },
  }

  return <Layout
    account={account}
    page={page}
    menu={menu}
    markets={markets.items}
    condensedFooter={true}
  >
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
    >
      <Configure facetingAfterDistinct={false} hitsPerPage={25} />
      <Search markets={markets.items} />
    </InstantSearch>
  </Layout>
}

export const query = graphql`
  query getSearchData($account: ID) {
    root {
     account: getAccountById(id: $account) {
        name
        title
        googleSiteVerification
        menu {
          title
          href
          subMenu {
            title
            subTitle
            href
          }
        }

        markets(sort: [["market", "1"]]) {
          count
          items {
            title: market
            state {
              name
            }
            marketPage {
              slug
              searchQueryParams
            }
            submarkets {
              title: submarket
              marketPage {
                slug
                searchQueryParams
              }
            }
            apartments(filter: { status:published } sort: [["name", "1"]]) {
              items {
                name
                marketingWebsiteUrl
              }
            }
          }
        }
      }
    }
  }
`

export default SearchTemplate
