import React from 'react'
import styled from 'styled-components'

const Address = styled.span`
  font-size: 1em;
  margin-bottom: .2em;
`

const formatAddress = ({ line1, city, state, postalCode }) =>
  `${ line1 } - ${ city }, ${ state } ${ postalCode }`

export default props =>
  <Address>{formatAddress(props.address)}</Address>
