import React from 'react'
import styled from 'styled-components'

import Dropdown from './Dropdown'
import Link from './Link'

const StyledBreadcrumb = styled.ol`
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  margin-bottom: 1em;
  list-style: none;
  border-radius: .25em;
  background-color: ${ props => props.theme.breadcrumb.backgroundColor };
  font-size: 1em;
  padding-left: 0;
`

const Breadcrumb = ({ children, ...props }) =>
  <div role='navigation' aria-label='Breadcrumbs'>
    <StyledBreadcrumb {...props}>{children}</StyledBreadcrumb>
  </div>

Breadcrumb.Item = styled.li`
  & + & {
    padding-left: .5em;
  }

  & + &::before {
    display: inline-block;
    padding-right: .5em;
    content: ">";
    color: ${ props => props.theme.breadcrumb.bodyColor };
  }
`

Breadcrumb.Link = styled(Link)`
  border: 0;
  background-color: transparent;
  color: ${ props => props.theme.breadcrumb.bodyColor };

  &[aria-haspopup='true']::after {
    content: ' ';
    display: inline-block;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${ props => props.theme.breadcrumb.bodyColor };
  }
`

Breadcrumb.Toggle = styled(Dropdown.Toggle)`
  padding: 5px 10px;
  border-radius: 0;

  &[aria-haspopup='true']::after {
    content: ' ';
    display: inline-block;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${ props => props.theme.breadcrumb.bodyColor };
  }
`

Breadcrumb.Header = styled.div`
  font-weight: bold;
  color: ${ props => props.theme.colors.darkgrey };
  padding: .25rem 1.25rem;
`

export default Breadcrumb
