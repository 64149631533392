import React, { useState } from 'react'
import { connectGeoSearch } from 'react-instantsearch-dom'

import { InputGroup, Label } from './Form'
import Dropdown from '../Dropdown'
import { groupBy } from '../../utils'
import { isSameBoundingBox } from './utils'
import Breadcrumb from '../Breadcrumb'

const getBoundingBox = obj =>
  obj && obj.marketPage && obj.marketPage.searchQueryParams &&
    obj.marketPage.searchQueryParams.boundingBox

const LocationGeoSearch = ({ markets, refine, currentRefinement }) => {
  const states = groupBy(markets, 'state.name')

  const [show, setShow] = useState(false)

  const handleKeyDown = e => e.key === 'Escape' && setShow(false)

  const selectLocation = location => {
    const boundingBox = getBoundingBox(location)
    if (boundingBox) {
      refine(boundingBox)
      setShow(false)
    }
  }

  const dropdownItems = []

  Object.keys(states).sort().forEach(state => {
    const markets = states[state].filter(getBoundingBox)
    if (markets.length === 0) {
      return
    }

    dropdownItems.push({
      component: Breadcrumb.Header,
      props: {
        children: state,
      }
    })

    markets.forEach(market => {
      const submarkets = market.submarkets.filter(getBoundingBox)

      dropdownItems.push({
        component: Dropdown.Item,
        props: {
          children: market.title,
          onClick: () => selectLocation(market),
          ...market.marketPage,
        }
      })

      submarkets.forEach(submarket => {
        dropdownItems.push({
          component: Dropdown.Item,
          props: {
            children: submarket.title,
            indent: 2,
            onClick: () => selectLocation(submarket),
            ...submarket.marketPage,
          }
        })
      })
    })
  })

  const found = dropdownItems.find(i => {
    const { searchQueryParams: { boundingBox } = {} } = i.props
    return isSameBoundingBox(boundingBox, currentRefinement)
  })

  return <InputGroup>
    <Label>Location</Label>
    <Dropdown onKeyDown={handleKeyDown}
      onOutsideClick={() => setShow(false)}
    >
      <Breadcrumb.Toggle
        aria-expanded={show}
        aria-haspopup='true'
        onClick={() => setShow(!show)}
      >
        {found ? found.props.children : 'Show All'}
      </Breadcrumb.Toggle>
      <Dropdown.Menu show={show}>
        {dropdownItems.map(
          ({ component: Component, props }, i) =>
            <Component key={i} {...props}
              className={found && found.props === props ? 'active' : ''}
            />
        )}
      </Dropdown.Menu>
    </Dropdown>
  </InputGroup>
}

export default connectGeoSearch(LocationGeoSearch)
