import styled from 'styled-components'

export default styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 7.5px 20px;
  color: ${ props => props.theme.card.ad.bodyColor };
  background: ${ props => props.theme.card.ad.backgroundColor };
  box-shadow: ${ props => props.theme.card.ad.boxShadow || 'none' };

  @media (min-width: 768px) {
    top: 10px;
    right: -10px;
  }
`
