import React from 'react'
import styled from 'styled-components'
import { Marker, Popup } from 'react-map-gl'
import { ReactComponent as MapMarker } from '../../images/faMapMarker.svg'

const StyledMarker = styled(Marker)`
  svg path {
    fill: ${ props => props.active
    ? props.theme.map.apartment.active.backgroundColor
    : props.theme.map.apartment.backgroundColor };
  }
`

const Label = styled(Popup)`
  .mapboxgl-popup-content {
    display: none;
    text-align: center;
    font-family: ${ props => props.theme.fonts.header };
    padding: 0 5px;
  }
`

const Container = styled.div`
  &:hover ${ Label } .mapboxgl-popup-content {
    display: block;
  }
`

export default ({ name, coordinates, active, onClick }) =>
  <Container>
    <StyledMarker
      active={active}
      className={active ? 'active' : ''}
      longitude={coordinates.lng}
      latitude={coordinates.lat}
      offsetTop={-24.5}
      offsetLeft={-8}>
      <MapMarker
        onClick={onClick}
        height={24}
        width={16}
      />
    </StyledMarker>
    <Label
      longitude={coordinates.lng}
      latitude={coordinates.lat}
      closeButton={false}
      closeOnClick={false}
      tipSize={0}
      offsetTop={-24.5}
      offsetLeft={-8}>
      {name}
    </Label>
  </Container>
