import React from 'react'
import styled from 'styled-components'
import { connectPagination } from 'react-instantsearch-dom'

import ButtonGroup, { Button } from './ButtonGroup'
import { Col } from '../Grid'

const Container = styled(Col)`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  > * {
    flex: 0;
  }
`

const PageLinks = ({ currentRefinement, nbPages, refine }) => {
  const start = Math.floor((currentRefinement - 1) / 5) * 5
  const end = Math.min(start + 5, nbPages)
  const pages = []
  for (let i = start; i < end; i++) {
    pages.push(i)
  }

  return <Container>
    <ButtonGroup>
      <Button key={start}
        onClick={() => refine(start)}
        disabled={start === 0}
      >
        &lt;
      </Button>
      {pages.map(n => {
        const page = n + 1
        return <Button key={page}
          onClick={() => refine(page)}
          disabled={currentRefinement === page}
        >
          {page}
        </Button>
      })}
      <Button key={end + 1}
        onClick={() => refine(end + 1)}
        disabled={nbPages < end + 1}
      >
        &gt;
      </Button>
    </ButtonGroup>
  </Container>
}

export default connectPagination(PageLinks)
