import React from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'

import ButtonGroup, { Button } from './ButtonGroup'
import { InputGroup, Label } from './Form'

const BedroomRefinementList = ({ items, refine }) => {
  const options = ['0', '1', '2', '3', '4'].map(n => {
    const item = items.find(i => i.label === n)
    return {
      ...item,
      count: item ? item.count : 0,
      label: n === '0' ? 'Studio' : n
    }
  })

  return <InputGroup>
    <Label>Bedrooms</Label>
    <ButtonGroup>
      {options.map(item =>
        <Button
          key={item.label}
          disabled={item.count === 0 && !item.isRefined}
          active={item.isRefined}
          onClick={() => refine(item.value)}>
          {item.label}
        </Button>
      )}
    </ButtonGroup>
  </InputGroup>
}

export default connectRefinementList(BedroomRefinementList)
