import React from 'react'
import styled from 'styled-components'
import {
  formatPrice,
  groupBy,
  sortBy,
  mapKeys,
  kebabCase
} from '../../utils'

import { Row } from '../Grid'

const Container = styled(Row)`
  flex: 1 0 auto;
  align-items: flex-end;
  margin: 0;
`

const Price = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  color: ${ props => props.theme.card.headerColor };
  max-width: 33.33%;
  flex: 0 0 33.33%;
  text-align: center;
  padding-bottom: .5em;

  span {
    display: block;
    text-transform: uppercase;
    color: ${ props => props.theme.card.bodyColor };
    font-size: .8em;
    font-weight: 400;
    padding-bottom: .2em;
  }
`

const NoPricing = styled.div`
    padding-bottom: .2em;
`

const Availability = props => {
  const bedrooms = props.floorPlans ? groupBy(props.floorPlans, 'bedrooms') : {}
  const keys = Object.keys(bedrooms)
  return <Container>
    {keys.length > 0 ? keys.map((n, i) => {
      if (i > 2) {
        return null
      }
      const overflow = i === 2 && keys.length > 3
      const availability = bedrooms[n]
        .reduce((acc, fp) => [...acc, ...fp.availability], [])
      const [price] = sortBy(availability, 'unitRent')
      const data = mapKeys(price, (val, key) => 'data-' + kebabCase(key))
      return <Price key={n} {...data}>
        <span>{n === '0' ? 'studio' : n + (overflow ? '+' : '') + ' bed'}</span>
        {price ? formatPrice(price.unitRent) : 'Contact'}
      </Price>
    }) : <NoPricing>Visit website for pricing</NoPricing>}

  </Container>
}

export default Availability
