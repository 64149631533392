import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connectRange } from 'react-instantsearch-dom'

import { InputGroup, Label } from './Form'
import Dropdown from '../Dropdown'

const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 5px 10px;
  border-radius: 0;

  &[aria-haspopup='true']::after {
    content: ' ';
    display: inline-block;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${ props => props.theme.breadcrumb.bodyColor };
  }
`

const getTimestamp = diff => {
  const now = new Date()
  now.setHours(0)
  now.setMinutes(0)
  now.setSeconds(0)
  now.setMilliseconds(0)
  now.setDate(now.getDate() + diff)
  return now.getTime()
}

const MoveInDateRange = ({ min, max, refine, currentRefinement }) => {
  const options = [
    {
      label: 'Show All',
      range: { min, max },
    },
    {
      label: 'Next 7 Days',
      range: { min, max: Math.min(getTimestamp(7), max) },
    },
    {
      label: 'Next 30 Days',
      range: { min, max: Math.min(getTimestamp(30), max) },
    },
    {
      label: 'More Than 30 Days',
      range: { min: Math.max(getTimestamp(30), min), max },
    },
  ]

  const [option, setOption] = useState()
  const [show, setShow] = useState(false)

  const handleKeyDown = e => e.key === 'Escape' && setShow(false)

  const selectOption = opt => {
    const { range } = opt
    if (range.min === option.min && range.max === option.max) {
      refine(options[0])
      setOption(options[0])
    } else {
      refine(range)
      setOption(opt)
    }

    setShow(false)
  }

  useEffect(() => {
    if (!option) {
      const found = options.find(
        ({ range }) =>
          range.min === currentRefinement.min &&
          range.max === currentRefinement.max
      )

      if (found) {
        setOption(found)
      }
    }
  }, [currentRefinement.min, currentRefinement.max])

  return <InputGroup>
    <Label>Move-In Date</Label>
    <Dropdown onKeyDown={handleKeyDown}
      onOutsideClick={() => setShow(false)}
    >
      <DropdownToggle
        aria-expanded={show}
        aria-haspopup='true'
        onClick={() => setShow(!show)}
      >
        {option ? option.label : 'Show All'}
      </DropdownToggle>
      <Dropdown.Menu show={show}>
        {options.map(opt =>
          <Dropdown.Item key={opt.label}
            className={option && option.label === opt.label ? 'active' : ''}
            onClick={() => selectOption(opt)}
          >
            {opt.label}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  </InputGroup>
}

export default connectRange(MoveInDateRange)
