import React from 'react'
import styled from 'styled-components'

import Link from '../Link'
import { ReactComponent as Map } from '../../images/faMap.svg'
import { ReactComponent as Close } from '../../images/faTimes.svg'

const MapToggle = styled(Link).attrs({
  'aria-label': 'Show/Hide Map',
})`
  font-size: .9em;

  height: 38px;
  width: 38px;
  position: fixed;
  top: 74px;
  right: 15px;
  z-index: 1020;
  padding: 0;
  border-radius: .25em;
  color: ${ props => props.theme.map.toggle.bodyColor };
  background-color: ${ props => props.theme.map.toggle.backgroundColor };
  border: 1px solid ${ props => props.theme.map.toggle.borderColor };
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }
`

export default ({ isOpen, ...props }) => {
  const Icon = isOpen ? Close : Map

  return <MapToggle {...props}>
    <Icon width={16} height={16} />
  </MapToggle>
}
