import { Component } from 'react'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    if (error) {
      return { hasError: true }
    }
  }

  componentDidCatch (error, info) {
    console.log(error, info)
  }

  render () {
    return this.state.hasError ? null : this.props.children
  }
}

export default ErrorBoundary
