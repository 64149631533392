import styled from 'styled-components'

import { Row, Col } from '../Grid'

const Card = styled(Col)`
  margin-bottom: 30px;

  @media (min-width: 992px) {
    flex: 0 0 ${ props => props.fullWidth ? '100%' : '50%' };
    max-width: ${ props => props.fullWidth ? '100%' : '50%' };
  }
`

const Apartments = styled(Row)`
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px)  {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`

const Map = styled.div`
  width: 100%;
  position: fixed;
  top: 64px;
  z-index: 1000;
  transition: right .15s ease;
  right: ${ props => props.show ? '0' : '-100%' };
  background-color: ${ props => props.theme.map.backgroundColor };

  height: calc(100% - 64px);

  .mapboxgl-popup-content {
    background-color: ${ props => props.theme.map.popup.backgroundColor };
    width: 250px;
  }

  .mapboxgl-popup {
    z-index: 2;
  }

  .mapboxgl-marker.active {
    z-index: 1;
  }

  @media (min-width: 768px) {
    right: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

export default {
  Card,
  Apartments,
  Map,
}
