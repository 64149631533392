import React from 'react'
import styled from 'styled-components'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { ReactComponent as Undo } from '../../images/faUndo.svg'
import ButtonGroup, { Button } from './ButtonGroup'
import { InputGroup, Label } from './Form'
import Link from '../Link'

const ButtonLink = styled(Link)`
  padding: 5px 10px;
  text-align: center;
  text-decoration: underline;
  border: 0;
  background-color: ${ props => props.theme.body.backgroundColor };
`

const Reset = ({ attributes, items, refine, link }) => {
  const onClick = () => {
    const undo = items.filter(item => attributes.indexOf(item.attribute) > -1)
    refine(undo)
  }

  return link
    ? <ButtonLink as='button' onClick={onClick}>Reset filters</ButtonLink>
    : <InputGroup>
      <Label>Reset</Label>
      <ButtonGroup>
        <Button aria-label='Reset Filters' onClick={onClick}>
          <Undo width={16} height={16} />
        </Button>
      </ButtonGroup>
    </InputGroup>
}

export default connectCurrentRefinements(Reset)
