import React from 'react'

import Card from './Card'
import { Row } from './Grid'

import {
  mapKeys
} from '../utils'

const ApartmentCard = ({
  defaultPhoto,
  name,
  address,
  prospectPhoneNumber,
  floorPlans,
  availability,
  adLabel,
  marketingWebsiteUrl,
  onClick,
  popup = false,
  layout = 'column',
}) => {
  const apartment = {
    name,
    ...mapKeys(address, (v, k) => 'address.' + k),
    adLabel,
    popup,
    layout,
    marketingWebsiteUrl,
    prospectPhoneNumber,
  }

  return <Card onClick={onClick} popup={popup} layout={layout}>
    <Card.Image {...defaultPhoto} />
    {adLabel ? <Card.AdLabel>{adLabel}</Card.AdLabel> : null}
    <Card.Body popup={popup}>
      <Card.Title>{name}</Card.Title>
      <Card.Address address={address} />
      <Card.Availability
        floorPlans={floorPlans}
      />
      <Row>
        <Card.Website {...apartment} />
        <Card.Phone {...apartment} />
      </Row>
    </Card.Body>
  </Card>
}

export default ApartmentCard
