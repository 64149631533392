import React, { useState } from 'react'
import { useMedia } from 'use-media'

import Button from './Button'
import { Col } from '../Grid'

export default apartment => {
  const [showNumber, setShowNumber] = useState()
  const isTabletOrLarger = useMedia({ minWidth: 768 })

  const {
    name: label,
    prospectPhoneNumber: phone,
  } = apartment

  const href = `tel:+1${ (phone || '').replace(/\D/g, '') }`

  const tracking = {
    category: 'Apartment Card Phone Number',
    action: 'Call',
    label,
    ...apartment,
  }

  const props = { href, tracking }

  if (isTabletOrLarger && !showNumber) {
    props.onClick = event => {
      event.preventDefault()
      setShowNumber(true)
    }

    props.tracking.action = 'Reveal'
  }

  return phone
    ? <Col flex={1}>
      <Button {...props}>
        {showNumber ? phone : 'Call Us'}
      </Button>
    </Col>
    : null
}
