import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  background-color:
  ${ props => {
    const { buttonGroup } = props.theme
    const key = props.active ? 'active' : 'default'
    return buttonGroup[key].backgroundColor
  } };
  color:
  ${ props => {
    const { buttonGroup } = props.theme
    const key = props.active ? 'active' : 'default'
    return buttonGroup[key].bodyColor
  } };
  border-color:
  ${ props => {
    const { buttonGroup } = props.theme
    const key = props.active ? 'active' : 'default'
    return buttonGroup[key].borderColor
  } };


  &:disabled {
    background-color:
    ${ props => props.theme.buttonGroup.disabled.backgroundColor };
    color:
    ${ props => props.theme.buttonGroup.disabled.bodyColor };
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: calc(2.25rem + 2px);

  > ${ Button } {
    flex: 1 1 auto;
    border: 1px solid ${ props => props.theme.buttonGroup.default.borderColor };
    padding: 5px 10px;
  }

  > ${ Button }:not(:first-child) {
    border-left: 0;
  }
`

export default ({ children }) =>
  <Buttons>{children}</Buttons>
