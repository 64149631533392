import styled from 'styled-components'
import Link from '../Link'

const CardLink = styled(Link)`
  color:
  ${ ({ theme, type = 'default' }) => theme.card.button[type].bodyColor };
  background-color:
  ${ ({ theme, type = 'default' }) => theme.card.button[type].backgroundColor };
  width: 100%;
  letter-spacing: .5px;
  display: block;
  border: 0;
  border-radius: 0;
  font-size: 1em;
  padding: .9em;
  text-align: center;

  &:hover {
    color:
    ${ ({ theme, type = 'default' }) => theme.card.button[type].bodyColor };
  }
`

export default CardLink
