import styled from 'styled-components'

const CardBody = styled.div`
  background-color: ${ props => props.theme.card.backgroundColor };
  padding: 1.25em;
  z-index: 1;
  min-height: ${ props => props.popup ? '180' : '250' }px;
  display: flex;
  flex-direction: column;
  font-size: ${ props => props.popup ? '.9' : '1' }em;
  font-weight: 400;
  color: ${ props => props.theme.card.bodyColor };
  border-width: ${ props => props.popup ? 0 : '2px' };
  border-style: solid;
  border-color: ${ props => props.theme.card.border.color };
  cursor: pointer;
  box-shadow: ${ props => props.theme.card.boxShadow || 'none' };
  margin-left: ${ props => props.popup ? 0 : '15px' };
  margin-right: ${ props => props.popup ? 0 : '15px' };

  @media (min-width: 768px) {
    margin-top: 0;
    border: 0;
    margin-left: 0;
    margin-right: 0;
  }
`

export default CardBody
