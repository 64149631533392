import styled from 'styled-components'

import Button from '../Card/Button'

export default styled.div`
  flex: 1 1 auto;
  padding: 0 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${ Button } {
    text-transform: none;
  }

  @media (min-width: 768px) {
    display: none;
  }
`
