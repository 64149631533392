import React from 'react'
import styled from 'styled-components'
import { connectRange } from 'react-instantsearch-dom'
import Rheostat from 'rheostat'
import 'rheostat/initialize'

import { Label } from './Form'
import { formatPrice } from '../../utils'

const RangeSlider = styled.div`
  @media (min-width: 768px) {
    flex: 1 1 auto;
  }

  padding: 0 15px;

  > .rheostat {
    position: relative;
    width: 100%;
    height: 36px;

    .handleContainer {
      position: relative;
      width: calc(100% - 16px);
    }

    .DefaultHandle_handle {
      z-index: 1;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: ${ props => props.theme.slider.button.backgroundColor };
      border-color: ${ props => props.theme.slider.button.borderColor };
    }

    > .DefaultBackground {
      background-color: ${ props => props.theme.slider.backgroundColor };
      height: 4px;
      top: 6px;
      position: absolute;
      width: 100%;
    }

    > .DefaultProgressBar_progressBar {
      position: absolute;
      height: 4px;
      background-color:
      ${ props => props.theme.slider.progressBar.backgroundColor };
      top: 6px;
    }
  }
`

const RheostatMarker = styled.div`
  position: absolute;
  top: 16px;

  left: 0;

  & + & {
    left: auto;
    right: 0;
  }
`

const RheostatValue = styled.div`
  color: ${ props => props.theme.slider.bodyColor };
`

const PriceRange = ({ min, max, refine, currentRefinement }) =>
  min === max ? null
    : <RangeSlider>
      <Label>Rent</Label>
      <Rheostat
        min={min}
        max={max}
        values={[currentRefinement.min, currentRefinement.max]}
        onChange={({ values: [min, max] }) => refine({ min, max })}
      >
        <RheostatMarker>
          <RheostatValue>
            {formatPrice(Math.max(currentRefinement.min, min), '')}
          </RheostatValue>
        </RheostatMarker>
        <RheostatMarker>
          <RheostatValue>
            {formatPrice(Math.min(currentRefinement.max, max), '')}
          </RheostatValue>
        </RheostatMarker>
      </Rheostat>
    </RangeSlider>

export default connectRange(PriceRange)
