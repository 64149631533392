import styled from 'styled-components'

const CardTitle = styled.h5`
  font-size: 1.5em;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 0;
  color: ${ props => props.theme.card.headerColor };
  font-family: ${ props => props.theme.fonts.header };
`

export default CardTitle
