import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMedia } from 'use-media'
import { connectGeoSearch } from 'react-instantsearch-dom'

import Map from '../Map'
import Toggle from '../Map/Toggle'
import Container from '../Main/Container'

import { isSameBoundingBox } from './utils'
import { debounce } from '../../utils'

const RedoSearchButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 50px;
  height: 30px;
  background: red;
  color: white;
  border: 1px solid red;
  border-radius: 5px;

  &:disabled {
    background: lightgrey;
    border-color: lightgrey;
    color: grey;
  }
`

const MapGeoSearch = ({
  flyTo,
  setFlyTo,
  hits,
  refine,
  currentRefinement
}) => {
  const [mapRef] = useState(React.createRef())
  const [showMap, setShowMap] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isRefined, setIsRefined] = useState(false)
  const [ready, setReady] = useState(false)

  const toggleMap = () => {
    setShowMap(!showMap)
  }

  const redoSearch = () => {
    if (mapRef.current) {
      setReady(false)
      const {
        _ne: northEast,
        _sw: southWest,
      } = mapRef.current.getMap().getBounds()
      refine({ northEast, southWest })
    }
  }

  // always show map for tablet and larger devices
  const alwaysShowMap = useMedia({ minWidth: 768 })

  const handleViewportChange = () => {
    if (loaded && ready) {
      const bounds = mapRef.current && mapRef.current.getMap().getBounds()
      setIsRefined(isSameBoundingBox(bounds, currentRefinement))
    }
  }

  const {
    northEast = {},
    southWest = {}
  } = currentRefinement || {}

  useEffect(() => {
    if (loaded && ready) {
      setReady(false)
    } else if (loaded) {
      setReady(true)
      setIsRefined(true)
    }
  }, [northEast.lat, northEast.lng, southWest.lat, southWest.lng])

  return <>
    <Toggle as='button' onClick={toggleMap} isOpen={showMap} />
    <Container.Map show={showMap || alwaysShowMap}>
      <RedoSearchButton
        disabled={!loaded || !ready || isRefined}
        onClick={redoSearch}
      >
        Redo search here
      </RedoSearchButton>
      {showMap || loaded || alwaysShowMap ? <Map
        ref={mapRef}
        flyTo={flyTo}
        apartments={hits}
        submarkets={[]}
        toggleMap={toggleMap}
        onViewportChange={debounce(handleViewportChange, 200)}
        onTransitionEnd={() => {
          if (loaded && !ready) {
            setReady(true)
            setIsRefined(true)
          }
        }}
        boundingBox={currentRefinement}
        onLoad={() => {
          setLoaded(true)
          setReady(true)
          setIsRefined(true)
        }}
        onOpenPopup={apt => setFlyTo(apt)}
        onClosePopup={() => setFlyTo()}
      /> : null}
    </Container.Map>
  </>
}

export default connectGeoSearch(MapGeoSearch)
