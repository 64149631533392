import styled from 'styled-components'

import Address from './Address'
import AdLabel from './AdLabel'
import Availability from './Availability'
import Body from './Body'
import Button from './Button'
import Image from './Image'
import Phone from './Phone'
import Title from './Title'
import Text from './Text'
import Website from './Website'

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: ${ props => props.popup ? '300px' : 0 };
  word-wrap: break-word;
  background-color: ${ props => props.theme.body.backgroundColor };
  background-clip: border-box;
  margin-left: ${ props => props.popup ? 0 : '-15px' };
  margin-right: ${ props => props.popup ? 0 : '-15px' };

  > ${ Body } {
    margin: ${ props => props.popup ? 0 : '-40px 15px 0' };

    @media (min-width: 768px) {
      margin: ${ props => props.popup ? 0 : (
    props.layout === 'row'
      ? '15px 0 15px -40px'
      : '-40px 15px 0 15px'
  ) }
    }
  }

  > * {
    flex: 1 0 auto;
  }

  ${ AdLabel } {
    ${ props => props.popup ? 'display: none;' : '' }
  }

  @media (min-width: 768px) {
    border-width: ${ props => props.popup ? 0 : '1px' };
    border-style: solid;
    border-color: ${ props => props.theme.card.border.color };
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 992px) {
    flex-direction: ${ props => props.layout || 'column' };
    > * {
      ${ props => props.layout === 'row' ? 'flex: 0 0 50%' : '' };
      ${ props => props.layout === 'row' ? 'max-width: 50%' : '' };
    }
  }
`

Card.Address = Address
Card.AdLabel = AdLabel
Card.Availability = Availability
Card.Body = Body
Card.Button = Button
Card.Image = Image
Card.Phone = Phone
Card.Title = Title
Card.Text = Text
Card.Website = Website

export default Card
