import React from 'react'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  object-position: center center;
`

const Fill = styled.div`
  width: 100%;
  padding-bottom: 58.6854%;
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export default ({
  url = 'https://via.placeholder.com/425x250',
  localFile, ...props
}) =>
  localFile
    ? <GatsbyImage fluid={localFile.childImageSharp.fluid} {...props} />
    : <ImageWrapper>
      <Fill />
      <Image src={url} {...props} />
    </ImageWrapper>
