import styled from 'styled-components'

export const Title = styled.h1`
  padding-top: 50px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    padding-top: 10px;
  }
`

export const PricingDisclaimer = styled.div`
  color: ${ props => props.theme.main.bodyColor };
  font-size: .9em;
`

export const SubTitle = styled(PricingDisclaimer)`
  font-size: 1.1em;
  padding: 10px 0;
`
