import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  background-color: ${ props => props.theme.body.backgroundColor };

  @media (max-width: 767.9px) {
    width: 100%;
    height: calc(100% - 64px);
    position: fixed;
    top: 64px;
    z-index: 1025;

    transition: right .15s ease;
    right: ${ props => props.open ? 0 : '-100%' };
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;

  @media (min-width: 768px) {
    padding: 0 0 15px 15px;
  }
`

export const Label = styled.div`
  color: ${ props => props.theme.colors.darkgrey };
  font-size: .9em;
  font-family: ${ props => props.theme.fonts.header };
`
