const getFloat = (n, p = 10) => parseFloat(n).toPrecision(p)

export const isSameBoundingBox = (box1, box2) => {
  if (box1 && box2) {
    const a = {
      _ne: box1._ne || box1.northEast,
      _sw: box1._sw || box1.southWest,
    }
    const b = {
      _ne: box2._ne || box2.northEast,
      _sw: box2._sw || box2.southWest,
    }
    return getFloat(a._ne.lat) === getFloat(b._ne.lat) &&
      getFloat(a._ne.lng) === getFloat(b._ne.lng) &&
      getFloat(a._sw.lat) === getFloat(b._sw.lat) &&
      getFloat(a._sw.lng) === getFloat(b._sw.lng)
  }
}
