import React from 'react'
import styled from 'styled-components'
import { Popup } from 'react-map-gl'
import OutsideClickHandler from 'react-outside-click-handler'

import ApartmentCard from '../ApartmentCard'

const StyledPopup = styled(Popup)`
  .mapboxgl-popup-content {
    padding: 0;
  }
`

export default ({ apartment, onClose }) =>
  <StyledPopup
    longitude={apartment.coordinates.lng}
    latitude={apartment.coordinates.lat}
    dynamicPosition={false}
    closeOnClick={false}
    closeButton={false}
    onClose={onClose}>
    <OutsideClickHandler onOutsideClick={onClose}>
      <ApartmentCard popup {...apartment} />
    </OutsideClickHandler>
  </StyledPopup>
