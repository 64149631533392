import React from 'react'
import styled from 'styled-components'

import Link from '../Link'

const Filter = styled(Link).attrs({
  'aria-label': 'Show/Hide Filters',
})`
  @media (min-width: 768px) {
    background-color:
    ${ props => props.theme.dropdown.hover.backgroundColor };
    border: 1px solid
    ${ props => props.theme.dropdown.borderColor };
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 767.9px) {
    font-size: 16px;
    height: 38px;
    width: 65px;
    position: fixed;
    top: 74px;
    right: 55px;
    z-index: 1020;
    border-radius: .25em;
    color: #58585b;
    background-color: #ffffff;
    border: 1px solid
    ${ props => props.theme.dropdown.borderColor };
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
  }
`

const Mobile = styled.span`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`

const Desktop = styled.span`
  margin-left: 5px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

export default props =>
  <Filter {...props}>
    <Mobile>Filter</Mobile>
    <Desktop>Refine Search</Desktop>
  </Filter>
